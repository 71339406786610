import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ZelloButton from "./ZelloBtn";

const ZelloConnectButton = ({ channel, channelName, channelShortHash , desktopHref , img }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [loading, setLoading] = useState(false);
  const [connected, setConnected] = useState(false); // Tracks connection state

  const handleButtonClick = async () => {
    setLoading(true);

    const params = {
      channel,
      channelName,
      channelShortHash,
      nonce: "Zello.nonce", // Replace with actual nonce value
      mode: connected ? "disconnect" : "connect",
    };

    try {
      // Simulate API call
      const response = await fetch("/user/saveconnectchannel", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      });

      if (!response.ok) {
        throw new Error("Failed to connect/disconnect");
      }

      const data = await response.json();

      if (isMobile) {
        if (params.mode === "connect") {
          // Redirect for mobile clients
          if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
            setTimeout(() => {
              window.location.href = "http://i.zello.com/get";
            }, 25);
            window.location.href = `zello://${channel}?add_channel`;
          } else if (/Android/i.test(navigator.userAgent)) {
            // Android-specific handling
            const iframe = document.createElement("iframe");
            iframe.style.display = "none";
            iframe.src = `zello://${channel}?add_channel`;
            document.body.appendChild(iframe);

            setTimeout(async () => {
              const reverseState = await fetch("/user/reverse-state").then((res) => res.json());
              if (reverseState.state === "nozello") {
                window.location.href = "http://i.zello.com/get";
              }
              document.body.removeChild(iframe);
            }, 2000);
          }
        }
      }

      setConnected(params.mode === "connect");
    } catch (error) {
      console.error(error);
      alert("An error occurred while processing your request.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
     {/*  <button
        onClick={handleButtonClick}
        disabled={loading}
        style={{
          padding: "10px 20px",
          backgroundColor: connected ? "#f44336" : "#4CAF50",
          color: "#fff",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          fontSize: "16px",
        }}
      >
        {loading ? "Processing..." : connected ? "Disconnect" : "Connect"}
      </button> */}

      {/* Example usage of ZelloButton component */}
      <ZelloButton
        img={img}
        mobileHref={`zello://${channel}?add_channel`}
        desktopHref={desktopHref}
        alt={channelName}
      />
        
    </div>
  );
};

export default ZelloConnectButton;
