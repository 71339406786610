import React, { useEffect, useState } from "react";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import {
  AccountCircleOutlined,
  DirectionsCarOutlined,
  CreditCardOutlined,
  ExitToAppOutlined, // Logout icon
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useTheme } from "@mui/material/styles";

const clearSessionStorageForDomain = (domain) => {
  for (let i = 0; i < sessionStorage.length; i++) {
    const key = sessionStorage.key(i);
    if (key.startsWith(domain)) {
      sessionStorage.removeItem(key);
    }
  }
};

const navItems = [
  {
    text: "Information du compte",
    link: "profile",
    icon: <AccountCircleOutlined />,
  },
  {
    text: "Mon véhicule",
    link: "vehicule",
    icon: <DirectionsCarOutlined />,
  },
 /*  {
    text: "Carte adhérant",
    link: "itacard",
    icon: <CreditCardOutlined />,
  }, */
];

const Sidebar = ({ user, drawerWidth, isSidebarOpen, isNonMobile }) => {
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();
  const signOut = useSignOut();

  const handleLogout = () => {
    signOut();
    clearSessionStorageForDomain(".www.itadz.netlify.com");
    sessionStorage.clear();
    window.location.href = "/";
  };

  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);

  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Drawer
          open
          variant="permanent"
          anchor="left"
          sx={{
            width: isNonMobile ? drawerWidth : "70px", // Full width on desktop, compact on mobile
            "& .MuiDrawer-paper": {
              backgroundColor: "#FAF7F7",
              boxSizing: "border-box",
              width: isNonMobile ? drawerWidth : "70px",
              height: "calc(100vh - 65px)",
              overflowX: "hidden",
              position: "relative",
            },
          }}
        >
          {/* Navigation Links */}
          <List sx={{ paddingTop: "2rem" }}>
            {navItems.map(({ text, link, icon }) => {
              const isActive = active === link;

              return (
                <Tooltip key={text} title={!isNonMobile ? "" : text} placement="right">
                  <ListItem disablePadding sx={{ width: "100%" }}>
                    <ListItemButton
                      onClick={() => {
                        navigate(`/${link}`);
                        setActive(link);
                      }}
                      sx={{
                        display : "flex",
                        justifyContent : "center",
                        backgroundColor: isActive ? "#AEE2F9" : "transparent",
                        borderRadius: "10px",
                        padding: isNonMobile ? "10px" : "10px",
                        width: "100%",
                        color: isActive ? theme.palette.primary[600] : theme.palette.secondary[100],
                      }}
                    >
                      {/* Icon */}
                      <ListItemIcon sx={{ color: "black", minWidth: isNonMobile ? "40px" : "10px" }}>
                        {icon}
                      </ListItemIcon>

                      {/* Show Text only on Desktop */}
                      {isNonMobile && <ListItemText primary={text} />}
                    </ListItemButton>
                  </ListItem>
                </Tooltip>
              );
            })}
          </List>

            {/* Divider & Logout Button */}
            <Box width="100%" position="absolute" bottom="1.5rem" display="flex" flexDirection="column" alignItems="center">
              <Divider sx={{ width: "80%" }} />

              {isNonMobile ? (
                // Full Button with Text for Desktop
                <Box
                  sx={{
                    mt: 2,
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    cursor: "pointer",
                    color: "black",
                  }}
                  onClick={handleLogout}
                >
                  <ExitToAppOutlined />
                  <Typography>Se Déconnecter</Typography>
                </Box>
              ) : (
                // Icon-Only Button for Mobile
                <Tooltip title="Se Déconnecter" placement="right">
                  <IconButton onClick={handleLogout} sx={{ mt: 2, color: "black" }}>
                    <ExitToAppOutlined />
                  </IconButton>
                </Tooltip>
              )}
            </Box>

        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;
