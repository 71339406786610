import React, { useState, useEffect, useCallback } from "react";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

const RegionsDropDown = ({ posts, onFilterChange, isMobile }) => {
  const [selectedRegions, setSelectedRegions] = useState(["Toutes les régions"]); // By default, "All" regions are selected

  // Extract unique regions from posts
  const regions = [...new Set(posts?.map(post => post.region).filter(Boolean))]; // Unique and non-null regions

  // Memoize the filter change to avoid unnecessary re-renders
  const handleFilterChange = useCallback(() => {
    // If "Toutes les régions" is selected, trigger the callback with all posts
    if (selectedRegions.includes("Toutes les régions")) {
      onFilterChange(posts);
    } else {
      // Filter posts based on selected regions
      const filteredPosts = posts.filter(post => selectedRegions.includes(post.region));
      onFilterChange(filteredPosts);
    }
  }, [selectedRegions, posts, onFilterChange]);

  useEffect(() => {
    handleFilterChange();
  }, [handleFilterChange]); // Use the memoized function as a dependency

  // Handle region selection change
  const handleChange = (event) => {
    const value = event.target.value;
  
    // If "Toutes les régions" is selected, reset to only "Toutes les régions" (deselect other regions)
    if (value.includes("Toutes les régions") && !selectedRegions.includes("Toutes les régions")) {
      setSelectedRegions(["Toutes les régions"]);
    } 
    // If "Toutes les régions" is already selected and another region is selected, remove "Toutes les régions"
    else if (value.includes("Toutes les régions") && value.length > 1) {
      setSelectedRegions(value.filter((region) => region !== "Toutes les régions"));
    } 
    // If all regions are unselected, set back to "Toutes les régions"
    else if (value.length === 0) {
      setSelectedRegions(["Toutes les régions"]);
    } 
    // Otherwise, proceed with normal region selection
    else {
      setSelectedRegions(value);
    }
  };

  return (
    <FormControl 
      sx={{ 
        m: 1, 
        maxWidth: isMobile ? 100 : 230,
        minWidth: isMobile ? 100 : 230, // Dynamically adjust width based on isMobile
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center' 
      }} 
      size="small"
    >
      <Select
        id="regions-select"
        multiple
        value={selectedRegions}
        onChange={handleChange}
        renderValue={(selected) => selected.join(', ')}
        sx={{
          borderRadius: "25px",
          background: "#fff",
          minWidth: isMobile ? 100 : 200, // Adjust the select box width
          maxWidth: isMobile ? 100 : 200
        }}
      >
        {/* Menu item for selecting all regions */}
        <MenuItem value="Toutes les régions">
          <Checkbox checked={selectedRegions.includes("Toutes les régions")} />
          <ListItemText primary="Toutes les régions" />
        </MenuItem>

        {/* Dynamically render regions from posts */}
        {regions.map((region) => (
          <MenuItem key={region} value={region}>
            <Checkbox checked={selectedRegions.includes(region)} />
            <ListItemText primary={region} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default RegionsDropDown;
