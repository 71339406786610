import React from "react";
import Container from "@mui/material/Container";
import { Box, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom"; // Import Link and useLocation
import useMediaQuery from '@mui/material/useMediaQuery'; // Import useMediaQuery
import facebook from '../../assets/facebook_mini.png';
import twitter from '../../assets/x_mini.png';
import youtube from '../../assets/youtube_mini.png';
import instagram from '../../assets/instagram_mini.png';
import Mail from '../../assets/Mail.png';
import Phone from '../../assets/Phone.png';

const pages = [
  { name: "Accueil", url: "/home" },
  { name: "Contactez-nous", url: "/contact" }
];

const Footer = () => {
  const location = useLocation(); // Get the current route
  const isMobile = useMediaQuery('(max-width: 600px)'); // Media query for mobile view

  return (
    <Box
      sx={{
        marginTop: "5em",
        backgroundColor: "rgba(174, 226, 249, 0.77)", // AEE2F9 with 77% opacity
        padding: isMobile ? "1em 0" : "2em 0", // Adjust padding for mobile
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row", // Stack vertically on mobile
            justifyContent: "space-between",
            alignItems: isMobile ? "flex-start" : "center", // Adjust alignment for mobile
            flexWrap: "wrap",
            gap: isMobile ? "1em" : "0", // Add gap for mobile view
            minHeight: isMobile ? "auto" : "12em",
          }}
        >
          {/* Left side: Contact Information */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", width: isMobile ? "100%" : "auto" }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <img src={Mail} alt="Email" width={24} />
              <Typography component="a" href="mailto:contact@infotraficalgerie.com" sx={{ textDecoration: 'none', color: 'inherit' }}>
              contact@infotraficalgerie.com
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <img src={Phone} alt="Phone" width={24} />
            <Typography component="a" href="tel:+213698975975" sx={{ textDecoration: "none", color: "inherit" }}>
              +213 69 89 75 975
            </Typography>
            </Box>
          </Box>

          {/* Middle: Social Media Links */}
          <Box sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row", // Stack vertically on mobile
            gap: "20px",
            width: isMobile ? "100%" : "auto",
            justifyContent: isMobile ? "center" : "flex-start", // Center content on mobile
            alignItems: "center"
          }}>
            {pages.map((page, index) => (
              <Link
                to={page.url}
                key={index}
                style={{ textDecoration: "none", color: "black" }} // Remove default link styling
              >
                <Typography
                  sx={{
                    cursor: "pointer",
                    position: "relative",
                    transition: "all 0.3s ease", // Smooth animation for text changes
                    fontSize: "16px",
                    color: location.pathname === page.url ? "white" : "inherit", // Active page style
                    fontWeight: location.pathname === page.url ? "bold" : "normal", // Bold for active
                    "&:hover": {
                      fontWeight: "bold",
                      fontSize: "18px",
                      color: "white",
                    },
                    "&:hover::after": {
                      width: "100%",
                    },
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      left: 0,
                      bottom: 0,
                      height: "2px",
                      width: location.pathname === page.url ? "100%" : "0", // Show underline for active
                      backgroundColor: "white",
                      transition: "width 0.3s ease",
                    },
                  }}
                >
                  {page.name}
                </Typography>
              </Link>
            ))}
          </Box>

          {/* Right side: Social Media Icons */}
          <Box sx={{
            display: "flex",
            gap: "20px",
            alignItems: "center",
            justifyContent: isMobile ? "center" : "flex-end", // Center on mobile view
            width: isMobile ? "100%" : "auto"
          }}>
            <a href="https://www.facebook.com/traficalg">
              <img src={facebook} alt="Facebook" width={32} />
            </a>
            <a href="https://www.instagram.com/traficalg/">
              <img src={instagram} alt="Instagram" width={32} />
            </a>
            <a href="https://x.com/traficalg">
              <img src={twitter} alt="Twitter" width={32} />
            </a>
            <a href="https://www.youtube.com/c/InfoTraficAlgerieITA">
              <img src={youtube} alt="Youtube" width={32} />
            </a>
          </Box>
        </Box>

        {/* Footer bottom section */}
        <Box
          sx={{
            textAlign: isMobile ? "center" : "inherit", // Center text on mobile
            marginTop: "20px",
            display: "flex",
            justifyContent: isMobile ? "center" : "space-between", // Center for mobile
            alignItems: "center",
            flexWrap: "wrap",
            gap: isMobile ? "10px" : "0", // Add gap for mobile view
          }}
        >
          <Typography sx={{ fontSize: isMobile ? "12px" : "inherit" }}>Copyright © 2024 - 2025 Info Trafic Algérie ITA. Tous droits réservés</Typography>
          <Typography sx={{ fontSize: isMobile ? "12px" : "inherit" }}>
            Développé par <a style={{
              textDecoration: "none",
              fontWeight: "Bold",
              color: "#333333"
            }} href="https://www.linkedin.com/in/faycal-merabet-7b3234241/" target="_blank">Fayçal MERABET</a>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
