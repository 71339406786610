import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const getUserToken = () => {
  try {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("_auth=")) // Match only the _auth cookie
      ?.split("=")[1]; // Extract the value after "="

    if (!cookieValue) {
      console.warn("Token not found in cookies");
      return null;
    }

    // Decode the token to ensure it's properly formatted
    const decodedToken = decodeURIComponent(cookieValue);

    return decodedToken;
  } catch (error) {
    console.error("Error retrieving token from cookies:", error);
    return null;
  }
};

export const api = createApi({
  baseQuery: fetchBaseQuery({
      /* baseUrl: "https://info-trafic-algerie-2-0.onrender.com", */
      /* baseUrl: "http://127.0.0.1:5500", */
      baseUrl: process.env.REACT_APP_API_URL,
      prepareHeaders: (headers) => {
        headers.set("Content-Type", "application/json");
          headers.set("Authorization", getUserToken());
          return headers;
      }
  }),
  reducerPath: "adminApi",
  tagTypes: [
    "User",
    "Facebook",
    "Weather",
    "Followers",
    "CheckEmailPseudo",
    "AddUser",
    "contactForm"
  ],
  endpoints: (build) => ({
    getUser: build.query({
      query: (id) => `user/${id}`,
      providesTags: ["User"],
    }),
    getFacebookFeed: build.query({
      query: () => "facebook/",
      keepUnusedDataFor: 60,
      providesTags: ["Facebook"],
    }),
    getBusinessFollowers: build.query({
      query: () => "facebook/followers",
      providesTags: ["Followers"],
    }),
    getWeatherInfo: build.query({
      query: () => "weather/",
      providesTags: ["Weather"],
    }),
    checkEmailPseudo: build.query({
      query: ({ email, pseudo }) => ({
        url: "user/check-email-pseudo",
        method: "POST",
        params: { email, pseudo },
      }),
      transformResponse: (response) => response,
      providesTags: ["CheckEmailPseudo"],
      invalidatesTags: ["CheckEmailPseudo"],
    }),
    addUser: build.mutation({
      query: (formData) => ({
        url: "user/signup",
        method: "POST",
        body: formData,
      }),
      transformResponse: (response) => {
        return response;
      },
      invalidatesTags: ["User"], // Invalidate the User tag to refresh related queries
    }),
    userProfileInfos: build.mutation({
      query: (userId) => ({
        url: "profile",
        method: "POST",
        body: userId,
      }),
      transformResponse: (response) => {
        return response;
      },
      invalidatesTags: ["ProfileInfos"], // Invalidate the User tag to refresh related queries
    }),
    updateUser: build.mutation({
      query: ({ userId, updates }) => ({
        url: `profile`,
        method: "PATCH",
        body: { userId, updates },
      }),
      invalidatesTags: ["User"], // Refresh related queries
    }),
    sendMessage: build.query({
      query: (payload) => ({
          url: "forms/contact",
          method: "POST",
          body: payload, // Use body to send JSON payload
      }),
      transformResponse: (response) => response, // Optional: Customize response
      providesTags: ["contactForm"], // Provides tags for caching
      invalidatesTags: ["contactForm"], // Invalidates cache on success
  }),
  }),
});

export const {
  useGetUserQuery,
  useGetFacebookFeedQuery,
  useGetWeatherInfoQuery,
  useGetBusinessFollowersQuery,
  useLazyCheckEmailPseudoQuery,
  useAddUserMutation,
  useUserProfileInfosMutation,
  useUpdateUserMutation,
  useLazySendMessageQuery,

} = api;
