import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from '@mui/icons-material/ArrowBack';
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Logo from '../../assets/logo_ita.png';
import { colors } from "../../theme";
import { useGetUserQuery } from "state/api";

const pages = [
  { name: "Accueil", path: "/" },
  { name: "Contactez-nous", path: "/contact" }
];

const NavIta = ({color}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const userId = useSelector((state) => state.global.userId);

  const { data: user } = useGetUserQuery(userId);

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static" sx={{ background: 'rgba(255,255,255,0.08)' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
        <Link to="/" style={{ textDecoration: "none" }}>
            <Box
              component="img"
              src={Logo}
              alt="Info Trafic Algérie - ITA"
              sx={{ mr: 2, display: { xs: "flex", md: "flex" }, height: 40, cursor: "pointer" }}
            />
          </Link>

          {isMobile ? (
            <>
              <IconButton
                size="large"
                aria-label="menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
                sx={{ ml: "auto" }} // Align the menu icon to the right
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100vw",
                  height: "100vh",
                  backgroundColor: "rgba(0, 0, 0, 0.9)",
                  left: 0,
                  top: 0,
                  p: 0,
                  m: 0,
                }}
                PaperProps={{
                  sx: {
                    width: "100vw",
                    height: "100vh",
                    backgroundColor: "rgba(0, 0, 0, 0.9)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: 2,
                  },
                }}
              >
                <IconButton
                  onClick={handleCloseNavMenu}
                  sx={{
                    position: "fixed",
                    top: '2rem',
                    right: '2rem',
                    margin: "0rem",
                    color: "white",
                    zIndex: 1300,
                  }}
                >
                  <CloseIcon />
                </IconButton>
                {pages.map((page) => (
                  <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">
                      <Link to={page.path} style={{ textDecoration: 'none', color : isMobile ? "White" : color, fontSize: '1.5rem' }}>
                        {page.name}
                      </Link>
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box sx={{ display: "flex", gap: 2 }}>
                {pages.map((page) => (
                  <Button
                    key={page.name}
                    component={Link}
                    to={page.path}
                    sx={{ color: color, textTransform: "none" }}
                  >
                    {page.name}
                  </Button>
                ))}
              </Box>
              {user ? (
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: colors.secondary,
                    fontWeight: "Bold",
                    color: "black",
                    borderRadius: "10px",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: colors.accent,
                      color: "white",
                    },
                  }}
                  component={Link}
                  to="/profile"
                >
                  Mon Profil
                </Button>
              ) : (
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: colors.secondary,
                    fontWeight: "Bold",
                    color: "black",
                    borderRadius: "10px",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: colors.accent,
                      color: "white",
                    },
                  }}
                  component={Link}
                  to="/auth"
                >
                  Se connecter
                </Button>
              )}
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavIta;
