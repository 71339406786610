import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo , useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router , Navigate, Route, Routes } from "react-router-dom";
import { themeSettings } from "theme";
import Layout from "scenes/layout";
import Home from "scenes/dashboard";
import { signIn } from "state"; 
import { setUserInfo } from "state";
import Login from "scenes/login";
import { logOut } from './state/index';
import CreateAccount from "scenes/signUp/CreateAccount";
import AuthOutlet from '@auth-kit/react-router/AuthOutlet';
import ResetPassword from "scenes/ressetPassword/ResetPassword";
import Tips from "scenes/tips/Tips";
import Profile from "scenes/profile/profile";
import Vehicule from "scenes/profile/vehicule";
import Contact from "scenes/contact/contact";


function App() {
  const dispatch = useDispatch();
  const storedToken = sessionStorage.getItem("token");
  if (storedToken) {
      dispatch(signIn(storedToken));
  }
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);



  useEffect(() => {
    const storedToken = sessionStorage.getItem("token");
    if (storedToken) {
      try {
        // Assuming the token has an 'exp' field indicating its expiration time
        const tokenData = JSON.parse(atob(storedToken.split(".")[1]));
        const isTokenValid = tokenData.exp * 1000 > Date.now(); // Convert seconds to milliseconds
      
        if (isTokenValid) {
          // Token is still valid, dispatch signIn and update userData
          dispatch(signIn(storedToken));
          const userDataString = sessionStorage.getItem("userData");
          if (userDataString) {
            const userData = JSON.parse(userDataString);
            
            dispatch(setUserInfo(userData));
          }
        } else {
          // Token is expired, clear stored data
          dispatch(logOut());
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("userData");

          document.cookie = '_auth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    
          // Clear the session
          sessionStorage.clear();
          window.location.reload();
        }
      } catch (error) {
        console.error("Error parsing or validating token:", error);
      }
    }
  }, [dispatch]);



  return (
    <div className="app">
      <Router>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
          <Route path="/" element={<Navigate to="/home" replace />} />
          <Route path="/home" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          {/* <Route path="/conseils" element={<Tips />} /> */}
            
          <Route element={<AuthOutlet fallbackPath='/auth' />}>
                <Route element={<Layout />}>
                <Route path="/profile" element={<Profile />} />
                <Route path="/vehicule" element={<Vehicule />} />
                {/* <Route path="/itacard" element={<Profile />} /> */}
                
                </Route>
             </Route>

            <Route element={ <Login /> } path="/auth" />
            <Route element={ <CreateAccount /> } path="/join" />
            <Route element={ <ResetPassword /> } path="/reset" />
          </Routes>
        </ThemeProvider>
      </Router>
    </div>
  );
}

export default App;
