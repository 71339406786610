import React, { useState } from "react";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import NavIta from "components/ita/Navigation";
import Footer from "components/ita/Footer";
import { CircularProgress } from "@mui/material";
import { useLazySendMessageQuery } from "state/api";

const Contact = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [triggerSendMessage, { isFetching }] = useLazySendMessageQuery();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    subject: "",
    message: "",
  });

  const [isLoading, setIsLoading] = useState(false); // State for spinner
  const [apiMessage, setApiMessage] = useState("");

  const [errors, setErrors] = useState({});

  // Handle input change
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Validate form
  const validateForm = () => {
    let tempErrors = {};
    if (!formData.firstName) tempErrors.firstName = "Ce champ est requis";
    if (!formData.lastName) tempErrors.lastName = "Ce champ est requis";
    if (!formData.email) tempErrors.email = "Veuillez entrer un email valide";
    if (!formData.subject) tempErrors.subject = "Ce champ est requis";
    if (!formData.message) tempErrors.message = "Veuillez entrer un message";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  // Handle form submit
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    


    if(!validateForm()){
        return
    }

    setApiMessage(""); // Reset the previous API message
    setIsLoading(true); // Show the spinner while sending the message
  
    try {
      console.log("Submitting form:", formData);
      const response = await triggerSendMessage(formData).unwrap(); // Call the API
      setApiMessage("Message envoyé avec succès !");
      console.log("API Response:", response); // Debug successful response
    } catch (error) {
      setApiMessage(error?.data?.message || "Une erreur est survenue.");
      console.error("API Error:", error); // Debug API error
    } finally {
      setIsLoading(false); // Hide the spinner after API call
    }
  };

  return (
    <div>
      <NavIta color={'black'} />
      <Box
        sx={{
            backgroundColor: "#B3E5FC", // Light blue background
            padding: "2em",
            height : "10em",
            borderRadius: "10px",
            textAlign: "center",
            display : "flex",
            justifyContent : "center",
            alignContent :"center",
            alignItems :"center",
            mb: 3,
        }}
        >
        <Typography variant="h3" sx={{ fontWeight: "bold", color: "white" }}>
            Nous sommes là pour vous. Besoin d'aide ou d’informations ? Faites-nous signe !
        </Typography>
        </Box>
      {/* Contact Section */}
      <Container maxWidth="lg" sx={{ textAlign: "center", mt: 5, mb: 5 }}>
      
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", mb: 3 }}
        >
          Restons connectés !
        </Typography>

        {/* Contact Form */}
        <Box
          sx={{
            backgroundColor: "#F8F9FA",
            borderRadius: "15px",
            padding: isMobile ? "2em" : "3em",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {/* First Name */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Nom"
                  name="firstName"
                  variant="outlined"
                  value={formData.firstName}
                  onChange={handleChange}
                  error={!!errors.firstName}
                  helperText={errors.firstName}
                />
              </Grid>

              {/* Last Name */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Prénom"
                  name="lastName"
                  variant="outlined"
                  value={formData.lastName}
                  onChange={handleChange}
                  error={!!errors.lastName}
                  helperText={errors.lastName}
                />
              </Grid>

              {/* Email */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Adresse Email *"
                  name="email"
                  type="email"
                  variant="outlined"
                  value={formData.email}
                  onChange={handleChange}
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Grid>

              {/* Subject */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Sujet *"
                  name="subject"
                  variant="outlined"
                  value={formData.subject}
                  onChange={handleChange}
                  error={!!errors.subject}
                  helperText={errors.subject}
                />
              </Grid>

              {/* Message */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Votre message *"
                  name="message"
                  multiline
                  rows={4}
                  variant="outlined"
                  value={formData.message}
                  onChange={handleChange}
                  error={!!errors.message}
                  helperText={errors.message}
                />
              </Grid>

              {/* Submit Button */}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={isLoading}
                  sx={{
                    backgroundColor: "#89CFF0",
                    color: "black",
                    fontWeight: "bold",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#76B4DA",
                    },
                  }}
                >
                  {isLoading ? <CircularProgress size={24} sx={{ color: "white" }} /> : "Envoyer"}
                </Button>
                <Typography
                sx={{
                  marginTop: "1rem",
                  visibility: apiMessage ? "visible" : "hidden",
                  color: apiMessage.includes("succès") ? "green" : "red",
                }}
              >
                {apiMessage}
              </Typography>
              </Grid>
            </Grid>
          </form>
        </Box>

        {/* Contact Info */}
        <Grid container spacing={3} sx={{ mt: 5, justifyContent: "center" }}>
          {/* Phone */}
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "1.5em",
                backgroundColor: "#FFFFFF",
                borderRadius: "15px",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
              }}
            >
              <PhoneIcon sx={{ fontSize: 50, color: "#89CFF0" }} />
              <Typography variant="h6" sx={{ fontWeight: "bold", mt: 1 }}>
                Téléphone
              </Typography>
              <Typography sx={{ color: "#555" }}>+213 60 56 52 677</Typography>
            </Box>
          </Grid>

          {/* Email */}
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "1.5em",
                backgroundColor: "#FFFFFF",
                borderRadius: "15px",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
              }}
            >
              <EmailIcon sx={{ fontSize: 50, color: "#89CFF0" }} />
              <Typography variant="h6" sx={{ fontWeight: "bold", mt: 1 }}>
                Email
              </Typography>
              <Typography sx={{ color: "#555" }}>contact@infotraficalgerie.com</Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Footer />
    </div>
  );
};

export default Contact;
