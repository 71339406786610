import { InputAdornment, FormControl, Box, Button, Container, CssBaseline, TextField, Typography } from '@mui/material';
import { LockOutlined, EmailOutlined } from '@mui/icons-material';
import { useTheme, useMediaQuery } from '@mui/material';
import InputFieldITA from "../components/old/Input";
import { Link } from 'react-router-dom';

import Email from '../assets/@.png';
import Lock from '../assets/lock.png'
import BgSVG from '../assets/auth_img.jpg';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { setUserInfo, signIn } from 'state';
import { useNavigate } from 'react-router-dom';
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import FlexBetween from 'components/old/FlexBetween';

function Login() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [token, setToken] = useState('');
  const [waiting, setIsWaiting] = useState(false);
  const [error, setError] = useState('');
  const dispatch = useDispatch(); 
  const navigate = useNavigate();
  const signInFunction = useSignIn();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const handleSignIn = async () => {
      if (isSignedIn) {
        try {
          await signInFunction({
            auth: {
              token: token.token,
              type: 'Bearer',
            }
          });
          sessionStorage.setItem('token', token.token);
          sessionStorage.setItem('userData', JSON.stringify({
            userId : token.userId,
            userEmail: token.userEmail,
            userRole: token.role,
            pseudo: token.pseudo,
            img : token.img,
          }));
          dispatch(signIn(token.token));
          dispatch(
            setUserInfo({
              userId: token.userId,
              pseudo: token.pseudo,
              userEmail: token.userEmail,
              userRole: token.role,
              img : token.img,
            })
          );
          navigate('/');
        } catch (error) {
          console.error('Error during sign-in:', error);
        }
      }
    };

    handleSignIn();
  }, [isSignedIn, navigate, signInFunction, token, dispatch]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const userData = {
      email: document.querySelector('#email').value,
      password: document.querySelector('#password').value,
    };

    const apiUrl = process.env.REACT_APP_API_URL;

    try {
      const response = await axios.post(`${apiUrl}/user/signin`, userData, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*', // Set the allowed origin for the request
        },
      });
      if (response.status === 201) {
        
        dispatch(signIn(response.data.token));
          dispatch(
            setUserInfo({
              userId: response.data.userId,
              userEmail: response.data.userEmail,
              userRole: response.data.role,
              img : token.img,
              pseudo : response.data.pseudo
            })
          );
        //const responseData = await response.json();
        setToken(response.data);
        setIsSignedIn(true);
      } else {
        
      }
    } catch (error) {
      console.error('Error:', error);
      setError(error.message || 'Unknown error occurred');
    } finally {
      setIsWaiting(false);
    }
  };

  return (
    <Box className='Intro'  sx={{ display: 'flex', height: '100vh'  }}>
      {/* Left side without background image */}
      <Box 
        sx={{ 
          display: 'flex', 
          flex: 1, 
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent : "space-evenly",
          padding : "2em 0em 2em 0em",
          userSelect :"none"
        }}>
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems : "center",
            userSelect : "none"
          }} >
          <Typography 
            component="h1" 
            variant="h3"
            className='Wlcm_wezign' 
            sx={{ 
              fontSize: 30,
              fontFamily: 'raspoutine',
              fontWeight: 'bold',
              marginBottom: '10px', 
              marginTop: '20px'
            }}
          >
            C’est sympa de vous revoir ! 
          </Typography>
          <Typography 
            component="h1" 
            variant="h3" 
            sx={{ 
              fontSize: 16,
              fontFamily: 'raspoutine',
              color:'#0085FF',
              marginBottom: '10px', 
              marginTop: '10px' 
              }}
            >
            Entrez vous informations de connexion 
          </Typography>
          </Box>
          <form onSubmit={handleSubmit} variant="standard" style={{ width: '100%' }}>
            <Box sx={{ width: '100%', margin: '0 auto' }}>
              <Box sx={{
                display : "flex",
                flexDirection : "column",
                alignItems: "center",
                gap : "1em",
                margin : "2em 0em 2em 0em"
              }} >
              <InputFieldITA type="email" name="email" id="email" img={Email} color={"#0085FF"} placeHolder="Adresse e-mail"/>
              <InputFieldITA type="password" name="password" id="password" img={Lock} color={"#0085FF"} placeHolder="Mot de passe" />
              </Box>

              {error && (
                <Box sx={{
                  display:"flex",
                  justifyContent:"center"
                }} >
                  <Typography variant="body2" style={{ color: 'red', marginBottom: '16px' }}>
                  {error}
                </Typography>
                </Box>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  color: 'white',
                  backgroundColor: '#0085FF',
                  borderRadius: '77px',
                  marginBottom: '20px',
                  width: "17em",
                  height: "3em",
                  fontSize: "larger",
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  border: '1px solid #0085FF',

                  '&:hover': {
                    backgroundColor: '#0085FF',
                  },
                }}
                disabled={waiting}
              >
                {waiting ? 'Connexion...' : 'Connexion'}
              </Button>
              
              
            </Box>
          </form>

          <Box>
          <Typography 
                align="center"
                className='Wlcm_wezign'
                sx={{
                  fontSize: 12,
                  fontFamily: 'Roboto',
                  fontWeight: 'regular',
                  color: 'rgba(0, 0, 0, 0.4)',
                }}
              >
                En me connectant, je confirme que j'accepte les accords d'utilisation <br/> et reconnais que j'utilise mes propres identifiants de connexion.
              </Typography>
              <Box sx={{ textAlign: 'center' }}>
              { <Typography className='Wlcm_wezign' sx={{
                  fontWeight : '500'
                }} >
                Vous n'avez pas de compte ? <Link style={{color :"#0085FF" }} to="/join">créez-en un</Link>
                </Typography>}
               { <Typography className='Wlcm_wezign' sx={{
                  fontWeight : '500'
                }} >
                Mot de passe oublié ? <Link style={{color :"#0085FF" }} to="/reset">Réinitialisez-le</Link>
                </Typography>}
              </Box>
          </Box>
      </Box>
      {/* Right side with background image */}

      {!isMobile && (
           <Box 
           className='rightSideLogin'  
             sx={{
               flex: '1',
               width: '100%', // Set width to 100% on mobile, otherwise 50%
               height: '100%', // Always take up all the height of the screen
             }}
           >
             <img
               src={BgSVG}
               alt="Background"
               style={{
                 width: '100%', // Ensure the image takes up the entire width of its container
                 height: '100%', // Ensure the image takes up the entire height of its container
                 objectFit: 'cover',
               }}
             />
           </Box>
      )
      }
     
    </Box>
  ) 

      
}

export default Login;

