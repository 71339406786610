import React from "react";

import {
  useMediaQuery,
} from "@mui/material";
import Hero from "components/ita/Hero";


const Home = () => {

  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
 


  return (
    <>
      <Hero />
    </>
  );
};

export default Home;
