import React from 'react';
import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/audio.css';
import '@vidstack/react/player/styles/default/layouts/video.css';

import { MediaPlayer, MediaProvider, Poster, Track } from '@vidstack/react';
import { DefaultVideoLayout, defaultLayoutIcons } from '@vidstack/react/player/layouts/default';



import './VidStackIO.css';

import PosterImg from '../../assets/video_ita_cover.png';

// Define the track data or pass it as props if dynamic
const textTracks = [
  { src: 'https://example.com/track1.vtt', label: 'English', kind: 'subtitles', srclang: 'en' },
  { src: 'https://example.com/track2.vtt', label: 'French', kind: 'subtitles', srclang: 'fr' }
];

const VideoPlayer = () => {
  return (
    <MediaPlayer
      src="https://res.cloudinary.com/dakbbymwz/video/upload/v1737056922/ITA/durwhbavujycksxrrv6s.mp4"
      viewType="video"
      streamType="on-demand"
      logLevel="warn"
      crossOrigin
      playsInline 
      autoPlay={true}
      title="Info Trafic Algérie - ITA"
      poster={PosterImg}
    >
      <MediaProvider>
        <Poster className="vds-poster" />
        {/* {textTracks.map((track) => (
          <Track {...track} key={track.src} />
        ))} */}
      </MediaProvider>
      <DefaultVideoLayout
       /*  thumbnails="https://files.vidstack.io/sprite-fight/thumbnails.vtt" */
        icons={defaultLayoutIcons}
      />
    </MediaPlayer>
  );
};

export default VideoPlayer;
