import React from 'react';
import Travaux from '../../assets/travaux.png';
import Jam from '../../assets/traffic-jam.png';
import Accident from '../../assets/accident.png';

const TrafficCounts = ({ counts , isMobile }) => {
    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: isMobile ? '10px' : '20px', // Smaller gap for mobile
        flexWrap: 'nowrap',
        margin: '20px',
    };

    const boxStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap : isMobile ? "0" : "1rem",
        alignItems: 'center',
        border: '2px solid #007bff',
        borderRadius: '10px',
        padding: isMobile ? '10px' : '20px', // Smaller padding for mobile
        backgroundColor: '#f8f9fa',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        width: isMobile ? '4em' : '5em', // Smaller width for mobile
        height: isMobile ? '6em' : 'auto', // Adjust height for mobile
    };

    const boxStyled = {
        display: 'flex',
        flexDirection: 'column',
        gap : isMobile ? "0" : "1rem",
        justifyContent: 'center',
        alignItems: 'center',
        border: '2px solid #007bff',
        borderRadius: '10px',
        padding: isMobile ? '10px' : '20px', // Smaller padding for mobile
        backgroundColor: '#f8f9fa',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        width: isMobile ? '6em' : '8em', // Smaller width for mobile
        height: isMobile ? '6em' : 'auto', // Adjust height for mobile
        textAlign: 'center',
    };

    const imgStyle = {
        height: 'auto',
        marginBottom: isMobile ? "0" : '0px',
        width: isMobile ? '30px' : '40x', // Smaller images for mobile
    };

    const titleStyle = {
        textAlign: 'center',
        margin : isMobile ? '0px' : '0px 0px 20px 0px',
        fontWeight: 'bold',
        color: '#007bff',
        padding : 0
    };

    return (
        <div style={{
            marginBottom : "2em"
        }} >
            <h2 style={titleStyle}>les dernières 24h</h2>
            <div style={containerStyle}>
                <div style={boxStyle}>
                    <img width={40} src={Accident} alt="Accidents" style={imgStyle} />
                    <h2 style={{
                        margin : isMobile ? "0" : ".5rem"
                    }} >{counts.iAccident}</h2>
                    {!isMobile && <p>Accidents</p>}
                </div>
                <div style={boxStyled}>
                    <img src={Jam} alt="Traffic Jams" style={imgStyle} width={60} />
                    <h2 style={{
                        margin : isMobile ? "0" : ".5rem"
                    }} >{counts.iEmbouteillage}</h2>
                    {!isMobile && <p>Points d'embouteillage</p>}
                </div>
                <div style={boxStyle}>
                    <img width={40} src={Travaux} alt="Works" style={imgStyle} />
                    <h2 style={{
                        margin : isMobile ? "0" : ".5rem"
                    }} >{counts.iTravaux}</h2>
                    {!isMobile && <p>Travaux</p>}
                </div>
            </div>
        </div>
    );
};

export default TrafficCounts;
