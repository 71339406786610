import { Box, Typography, Button, Switch, Divider } from "@mui/material";
import { useUserProfileInfosMutation, useUpdateUserMutation } from "state/api";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

// Helper function to register the service worker
const registerServiceWorker = async () => {
    if ("serviceWorker" in navigator) {
        try {
            const registration = await navigator.serviceWorker.register("/service-worker.js");
            console.log("Service Worker registered:", registration);
            return registration;
        } catch (error) {
            console.error("Service Worker registration failed:", error);
        }
    } else {
        console.error("Service Worker is not supported in this browser.");
    }
};

// Helper function to convert the VAPID key
const urlBase64ToUint8Array = (base64String) => {
    const padding = "=".repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
};

const Profile = () => {
    const userId = useSelector((state) => state.global.userId);

    // RTK Queries and Mutations
    const [fetchUserProfile, { data: profileData, error: profileError, isLoading: isProfileLoading }] =
        useUserProfileInfosMutation();
    const [updateUser, { isLoading: updateIsLoading }] = useUpdateUserMutation();

    const [profileInfo, setProfileInfo] = useState(null);

    // Fetch Profile Data
    useEffect(() => {
        const fetchProfileData = async () => {
            if (userId) {
                try {
                    const response = await fetchUserProfile({ userId }).unwrap();
                    setProfileInfo(response);
                } catch (error) {
                    console.error("Error fetching profile info:", error);
                }
            }
        };
        fetchProfileData();
    }, [userId, fetchUserProfile]);

    // Handle Newsletter Toggle
    const handleNewsletterToggle = async (event) => {
        const newValue = event.target.checked;
        try {
            await updateUser({ userId, updates: { userReceiveNewsletter: newValue } });
            setProfileInfo((prev) => ({ ...prev, userReceiveNewsletter: newValue }));
        } catch (error) {
            console.error("Failed to update newsletter preference:", error);
        }
    };

    // Request Notification Permission
    const requestNotificationPermission = async () => {
        if ("Notification" in window) {
            const permission = await Notification.requestPermission();
            if (permission === "granted") {
                console.log("Notification permission granted.");
            } else if (permission === "denied") {
                console.error("Notification permission denied.");
            } else {
                console.log("Notification permission dismissed.");
            }
            return permission === "granted";
        }
        console.error("This browser does not support notifications.");
        return false;
    };

    // Subscribe to Push Notifications
    const subscribeToPushNotifications = async () => {
        try {
            const registration = await registerServiceWorker();
            const vapidPublicKey = process.env.REACT_APP_VAPID_PUBLIC_KEY;

            const subscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(vapidPublicKey),
            });

            console.log("Push Subscription:", subscription);

            // Send the subscription to the backend
            await fetch(`${process.env.REACT_APP_API_URL}/push/subscribe`, {
                method: "POST",
                body: JSON.stringify({ subscription }),
                headers: {
                    "Content-Type": "application/json",
                },
            });
            console.log("Subscription saved on the backend.");
        } catch (error) {
            console.error("Failed to subscribe to push notifications:", error);
        }
    };

    // Handle Notification Toggle
    const handleNotificationToggle = async (event) => {
        const newValue = event.target.checked;
        if (newValue) {
            const permissionGranted = await requestNotificationPermission();
            if (permissionGranted) {
                await subscribeToPushNotifications();
            }
        }
        try {
            await updateUser({ userId, updates: { userReceiveNotifications: newValue } });
            setProfileInfo((prev) => ({ ...prev, userReceiveNotifications: newValue }));
        } catch (error) {
            console.error("Failed to update notification preference:", error);
        }
    };

    if (isProfileLoading) {
        return <Typography>Loading...</Typography>;
    }

    if (profileError) {
        return <Typography>Error loading profile: {profileError.message}</Typography>;
    }

    return (
        <Box
            sx={{
                width: "70rem",
                margin: "auto",
                minHeight: "75vh",
                padding: "20px",
                borderRadius: "8px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#FAF7F7",
                fontFamily: "Arial, sans-serif",
            }}
        >
            {/* Pseudo */}
            <Typography sx={{ fontWeight: "bold", fontSize: "14px", marginBottom: "8px" }}>
                Pseudonyme:{" "}
                <Typography component="span" sx={{ fontWeight: "normal" }}>
                    {profileInfo?.pseudo || "N/A"}
                </Typography>
            </Typography>

            {/* Email */}
            <Typography sx={{ fontWeight: "bold", fontSize: "14px", marginBottom: "8px" }}>
                Adresse email:{" "}
                <Typography component="span" sx={{ fontWeight: "normal" }}>
                    {profileInfo?.email || "N/A"}
                </Typography>
            </Typography>

            {/* Password */}
            <Typography sx={{ fontWeight: "bold", fontSize: "14px", marginBottom: "16px" }}>
                Mot de passe:{" "}
                <Typography component="span" sx={{ fontWeight: "normal" }}>
                    **********
                </Typography>
            </Typography>

            <Divider sx={{ marginBottom: "16px" }} />

            {/* Newsletter */}
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "12px" }}>
                <Typography sx={{ fontSize: "14px" }}>Recevoir la newsletter :</Typography>
                <Switch
                    checked={profileInfo?.userReceiveNewsletter || false}
                    onChange={handleNewsletterToggle}
                    disabled={updateIsLoading}
                />
            </Box>

            {/* Notifications */}
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                <Typography sx={{ fontSize: "14px" }}>Activer les notifications :</Typography>
                <Switch
                    checked={profileInfo?.userReceiveNotifications || false}
                    onChange={handleNotificationToggle}
                    disabled={updateIsLoading}
                />
            </Box>

            {/* Deactivate Account */}
            {/* <Button
                variant="text"
                sx={{
                    color: "red",
                    fontWeight: "bold",
                    textTransform: "none",
                    fontSize: "14px",
                }}
            >
                Désactiver mon compte
            </Button> */}
        </Box>
    );
};

export default Profile;
