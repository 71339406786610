import { Box, Typography, TextField, Switch, Divider, IconButton, MenuItem, Select } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { useUserProfileInfosMutation, useUpdateUserMutation } from "state/api";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const fuelOptions = ["DIESEL", "ESSENCE", "ESS.GPL"];

const Vehicule = () => {
    const userId = useSelector((state) => state.global.userId);
    const [vehicleReminder, setVehicleReminder] = useState(false);

    // RTK Queries
    const [fetchUserProfile, { data: profileData, error: profileError, isLoading: isProfileLoading }] =
        useUserProfileInfosMutation();
    const [updateUser, { isLoading: updateIsLoading }] = useUpdateUserMutation();

    const [vehiculeInfo, setVehiculeInfo] = useState(null);
    const [editField, setEditField] = useState(null); // Track the field being edited

    // Fetch Profile Data
    useEffect(() => {
        const fetchProfileData = async () => {
            if (userId) {
                try {
                    const response = await fetchUserProfile({ userId }).unwrap();
                    console.log(response);
                    
                    setVehiculeInfo(response?.vehicule || {});
                    setVehicleReminder(response?.userReceiveVehiculeNotifications || false);
                } catch (error) {
                    console.error("Error fetching profile info:", error);
                }
            }
        };
        fetchProfileData();
    }, [userId, fetchUserProfile]);
    

    // Handle Edit Button Click
    const handleEdit = (field) => {
        setEditField(field);
    };

    // Handle Input Change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setVehiculeInfo((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleVehicleReminderChange = async (event) => {
        const newValue = event.target.checked;
        setVehicleReminder(newValue); // Update UI immediately
    
        try {
            await updateUser({ userId, updates: { vehicleReminder: newValue } }).unwrap();
        } catch (error) {
            console.error("Error updating vehicle reminder:", error);
            alert("Erreur lors de la mise à jour !");
        }
    };
    

    // Handle Date Change
    const handleDateChange = (field, newValue) => {
        setVehiculeInfo((prev) => ({
            ...prev,
            [field]: newValue ? newValue.toISOString() : "",
        }));
    };

    // Handle Save
    const handleSave = async (field) => {
        try {
            await updateUser({ userId, updates: { [`vehicleInfo.${field}`]: vehiculeInfo[field] } }).unwrap();
            setEditField(null);
        } catch (error) {
            console.error("Error updating vehicle info:", error);
            alert("Erreur lors de la mise à jour !");
        }
    };

    if (isProfileLoading) {
        return <Typography>Loading...</Typography>;
    }

    if (profileError) {
        return <Typography>Error loading vehicle information: {profileError.message}</Typography>;
    }

    if (!vehiculeInfo) {
        return <Typography>No vehicle information available.</Typography>;
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
                sx={{
                    width: "50rem",
                    margin: "auto",
                    minHeight: "50vh",
                    padding: "20px",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#FAF7F7",
                    fontFamily: "Arial, sans-serif",
                }}
            >
                <Typography sx={{ fontWeight: "bold", fontSize: "18px", marginBottom: "16px" }}>
                    Informations du Véhicule
                </Typography>

                {/* Marque */}
                <Box sx={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                    {editField === "marque" ? (
                        <TextField fullWidth name="marque" value={vehiculeInfo.marque} onChange={handleInputChange} />
                    ) : (
                        <Typography sx={{ fontWeight: "bold", fontSize: "14px", flex: 1 }}>
                            Marque: <Typography component="span" sx={{ fontWeight: "normal" }}>{vehiculeInfo.marque || "N/A"}</Typography>
                        </Typography>
                    )}
                    {editField === "marque" ? (
                        <IconButton onClick={() => handleSave("marque")}><SaveIcon /></IconButton>
                    ) : (
                        <IconButton onClick={() => handleEdit("marque")}><EditIcon /></IconButton>
                    )}
                </Box>

                {/* Modèle */}
                <Box sx={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                    {editField === "model" ? (
                        <TextField fullWidth name="model" value={vehiculeInfo.model} onChange={handleInputChange} />
                    ) : (
                        <Typography sx={{ fontWeight: "bold", fontSize: "14px", flex: 1 }}>
                            Modèle: <Typography component="span" sx={{ fontWeight: "normal" }}>{vehiculeInfo.model || "N/A"}</Typography>
                        </Typography>
                    )}
                    {editField === "model" ? (
                        <IconButton onClick={() => handleSave("model")}><SaveIcon /></IconButton>
                    ) : (
                        <IconButton onClick={() => handleEdit("model")}><EditIcon /></IconButton>
                    )}
                </Box>

                {/* Année */}
                <Box sx={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                    {editField === "year" ? (
                        <TextField fullWidth name="year" type="number" value={vehiculeInfo.year} onChange={handleInputChange} />
                    ) : (
                        <Typography sx={{ fontWeight: "bold", fontSize: "14px", flex: 1 }}>
                            Année: <Typography component="span" sx={{ fontWeight: "normal" }}>{vehiculeInfo.year || "N/A"}</Typography>
                        </Typography>
                    )}
                    {editField === "year" ? (
                        <IconButton onClick={() => handleSave("year")}><SaveIcon /></IconButton>
                    ) : (
                        <IconButton onClick={() => handleEdit("year")}><EditIcon /></IconButton>
                    )}
                </Box>

                {/* Type de carburant (Dropdown) */}
                <Box sx={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                    {editField === "fuelType" ? (
                        <Select
                            fullWidth
                            name="fuelType"
                            value={vehiculeInfo.fuelType || ""}
                            onChange={handleInputChange}
                        >
                            {fuelOptions.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    ) : (
                        <Typography sx={{ fontWeight: "bold", fontSize: "14px", flex: 1 }}>
                            Type de carburant: <Typography component="span" sx={{ fontWeight: "normal" }}>{vehiculeInfo.fuelType || "N/A"}</Typography>
                        </Typography>
                    )}
                    {editField === "fuelType" ? (
                        <IconButton onClick={() => handleSave("fuelType")}><SaveIcon /></IconButton>
                    ) : (
                        <IconButton onClick={() => handleEdit("fuelType")}><EditIcon /></IconButton>
                    )}
                </Box>

                <Divider sx={{ margin: "16px 0" }} />

                {/* Validité Assurance (Date Picker) */}
                <Box sx={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                    {editField === "insuranceValidity" ? (
                        <>
                            <MobileDatePicker
                                value={vehiculeInfo.insuranceValidity ? dayjs(vehiculeInfo.insuranceValidity) : null}
                                onChange={(date) => handleDateChange("insuranceValidity", date)}
                            />
                            <IconButton onClick={() => handleSave("insuranceValidity")}><SaveIcon /></IconButton>
                        </>
                    ) : (
                        <Typography sx={{ fontWeight: "bold", flex: 1 }}>
                            Date d'expiration de l'assurance: <Typography component="span">{vehiculeInfo.insuranceValidity ? dayjs(vehiculeInfo.insuranceValidity).format("DD/MM/YYYY") : "N/A"}</Typography>
                        </Typography>
                    )}
                    <IconButton onClick={() => handleEdit("insuranceValidity")}><EditIcon /></IconButton>
                </Box>

                {/* Validité Contrôle Technique (Date Picker) */}
                <Box sx={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                    {editField === "technicalControlValidity" ? (
                        <>
                            <MobileDatePicker
                                value={vehiculeInfo.technicalControlValidity ? dayjs(vehiculeInfo.technicalControlValidity) : null}
                                onChange={(date) => handleDateChange("technicalControlValidity", date)}
                            />
                            <IconButton onClick={() => handleSave("technicalControlValidity")}><SaveIcon /></IconButton>
                        </>
                    ) : (
                        <Typography sx={{ fontWeight: "bold", flex: 1 }}>
                            Date d'expiration du contrôle technique: <Typography component="span">
                                {vehiculeInfo.technicalControlValidity ? dayjs(vehiculeInfo.technicalControlValidity).format("DD/MM/YYYY") : "N/A"}
                            </Typography>
                        </Typography>
                    )}
                    <IconButton onClick={() => handleEdit("technicalControlValidity")}><EditIcon /></IconButton>
                </Box>

                <Divider sx={{ margin: "16px 0" }} />

                {/* Rappel Véhicule */}
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                    <Typography sx={{ fontSize: "14px" }}>Activer le rappel du véhicule :</Typography>
                    <Switch
                        checked={vehicleReminder}
                        onChange={handleVehicleReminderChange}
                        disabled={updateIsLoading}
                    />
                </Box>




            </Box>
        </LocalizationProvider>
    );
};

export default Vehicule;
