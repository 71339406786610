import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";


const ZelloButton = ({img , mobileHref , desktopHref , alt}) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
    <a
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100px",
        height: "100px",
        borderRadius: "50%",
        overflow: "hidden",
        backgroundColor: "#f0f0f0", // Fallback background color
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        textDecoration: "none", // Ensure no underline
        marginBottom : "1em"
      }}
      href={isMobile ? mobileHref : desktopHref}
      target={isMobile ? "_self" : "_blank"}
    >
      <img
        width={100}
        src={img}
        alt={alt}
        style={{
          borderRadius: "50%",
          objectFit: "cover",
        }}
      />
    </a>
    <a href={isMobile ? mobileHref : desktopHref}>{alt}</a>
    </>
  );
};

export default ZelloButton;
