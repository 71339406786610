import React, { useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Navbar from "components/old/Navbar";
import Sidebar from "components/old/Sidebar";
import {  useGetUserQuery } from 'state/api';
import NavIta from 'components/ita/Navigation';


function Layout() {
  const isNonMobile = useMediaQuery("(min-width : 600px)");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const userId = useSelector((state) => state.global.userId
  );



  console.log(userId, "Global state");
  

  const { data : user } = useGetUserQuery(userId);


  
  
  /* const { data2, error2, isLoading2  } = useGetloggedInMembersQuery(); */
 

  return (
    <Box  display={isNonMobile ? "flex" : "flex"} width="100%" height="100%" >
      <Box flexGrow={1} style={{ overflowY: 'auto' }} >
        <NavIta color={'black'} />
        <Box sx={{display : "flex"}} >
        <Sidebar  user={user || {}} isNonMobile={isNonMobile} drawerWidth={!isNonMobile ? "100%" : "250px"} isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
        <Outlet user={user} />
        </Box>
     </Box>
    </Box>
  )
}

export default Layout;
