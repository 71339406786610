import { createSlice } from "@reduxjs/toolkit";

const storedToken = sessionStorage.getItem('token');

const initialState = {
  mode: "light",
  userId: null,
  userName: null,
  userEmail: null,
  token: storedToken || null,
  userRole: null,
  userLastName: null, 
  img: null,
  pseudo : null,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    signIn: (state,action) => {
      state.token = action.payload;
  },
  logOut: () => initialState,
  setUserInfo: (state, action) => {
      const { userId,  userEmail , userRole , img , pseudo } = action.payload;
      state.userId = userId;
      state.userEmail = userEmail;
      state.userRole = userRole;
      state.img = img;
      state.pseudo = pseudo
  },
  },
});

export const { setMode , signIn , logOut , setUserInfo } = globalSlice.actions;

export default globalSlice.reducer;
