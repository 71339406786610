import React from 'react';
import { useGetWeatherInfoQuery } from "state/api";
import './WeatherWidget.css'; // You can still use the same CSS file

const WeatherApp = () => {
  const { data: weatherData, isLoading, error } = useGetWeatherInfoQuery();

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error fetching weather data!</p>;

  if (!weatherData) return <p>Loading weather data...</p>;

  const {
    location,
    weather: {
      main: { temp },
      weather,
      sys: { country },
    },
  } = weatherData;

  const weatherDescription = weather[0].description;
  const weatherIcon = `https://openweathermap.org/img/wn/${weather[0].icon}@2x.png`;

  return (
    <div className="weather-widget">
      <div className="weather-header">
        <h2>{location}, {country}</h2>
        <div>
        <p className="temperature">{Math.round(temp)}°C</p>
        <p>{weatherDescription}</p>
        </div>
        <img src={weatherIcon} alt={weatherDescription} />
      </div>
    </div>
  );
};

export default WeatherApp;
