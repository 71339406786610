import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Checkbox, FormControlLabel, FormControl, FormLabel, RadioGroup, Radio } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';
import { CircularProgress } from '@mui/material'; // Import CircularProgress from Material-UI
import { useDispatch } from 'react-redux';
import { useAddUserMutation, useLazyCheckEmailPseudoQuery } from '../../state/api';
import { setUserInfo, signIn } from '../../state/index';
import { useNavigate } from 'react-router-dom';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { motion } from 'framer-motion';
import BgSVG from '../../assets/auth_img.jpg';
import useSignIn from 'react-auth-kit/hooks/useSignIn';

const today = new Date().toISOString().split('T')[0];

const textFieldStyles = {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#0085FF', // Default border color
      },
      '&:hover fieldset': {
        borderColor: '#006bbf', // Border color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0085FF', // Border color when focused
      },
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: '#006bbf', // Label color when focused
    },
  };

  const wilayas = [
    "Adrar", "Chlef", "Laghouat", "Oum El Bouaghi", "Batna", "Bejaia", "Biskra", "Bechar", "Blida",
    "Bouira", "Tamanrasset", "Tebessa", "Tlemcen", "Tiaret", "Tizi Ouzou", "Alger", "Djelfa",
    "Jijel", "Setif", "Saida", "Skikda", "Sidi Bel Abbes", "Annaba", "Guelma", "Constantine",
    "Medea", "Mostaganem", "Msila", "Mascara", "Ouargla", "Oran", "El Bayadh", "Illizi",
    "Bordj Bou Arreridj", "Boumerdes", "El Tarf", "Tindouf", "Tissemsilt", "El Oued",
    "Khenchela", "Souk Ahras", "Tipaza", "Mila", "Ain Defla", "Naama", "Ain Temouchent",
    "Ghardaia", "Relizane"
  ];

const CreateAccount = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [waiting, setIsWaiting] = useState(false);
  const [error, setError] = useState('');
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [token, setToken] = useState('');
  const [step, setStep] = useState(0);
const [formData, setFormData] = useState({
  pseudo: '',
  email: '',
  password: '',
  confirmPassword: '',
  termsAccepted: false,
  personalInfo: {
    ignored: false,
    name: '',
    lastName: '',
    dateOfBirth: '', // Added Date of Birth field
    wilaya: '', // Added Wilaya selection
    commune: '', // Added Commune field
    phone: '',
    transportation: '', // New: Transportation method (Sans, Moto, Voiture, etc.)
    profession: '', // New: Profession field
    otherSkills: '' // New: Other Skills field
  },
  vehicleInfo: {
    ignored: false,
    marque: '',
    model: '',
    year: '',
    fuelType: '',
    insuranceValidity: '', // Maps to insurance date
    technicalControlValidity: '', // Maps to control date
  }
});

console.log(formData);


  const [errors, setErrors] = useState({});
  const [passwordStrength, setPasswordStrength] = useState('');
  const [isChecking, setIsChecking] = useState(false); // To handle loading state

  const signInFunction = useSignIn();

  const [triggerCheckEmailPseudo, { data : checkData, error : checkError }] = useLazyCheckEmailPseudoQuery();
  const [addUser, { isLoading, data : userData, error : userError }] = useAddUserMutation();
  
  

 
  
  useEffect(() => {
    const handleSignIn = async () => {
      if (isSignedIn && token?.token) {
        try {
          const success = signInFunction({
           auth: {
              token: token.token,
              type: 'Bearer',
            },
          });
  
          if (success) {
            
            sessionStorage.setItem('token', token.token);
            sessionStorage.setItem(
              'userData',
              JSON.stringify({
                userId: token.userId,
                userEmail: token.userEmail,
                userRole: token.role,
                pseudo: token.pseudo,
                img: token.img,
              })
            );
            dispatch(signIn(token.token));
            dispatch(
              setUserInfo({
                userId: token.userId,
                userEmail: token.userEmail,
                userRole: token.role,
                img: token.img,
                pseudo: token.pseudo,
              })
            );
            navigate('/');
          } else {
            setError('Sign-in failed.');
          }
        } catch (error) {
          setError('Error during sign-in: ' + error.message);
        }
      }
    };
  
    handleSignIn();
  }, [isSignedIn, token, dispatch, navigate, signInFunction]);
  
  useEffect(() => {
    if (formData.vehicleInfo.ignored && step === 2) {
      handleSubmit();
    }
  }, [formData.vehicleInfo.ignored, step]);

  // Update password strength in real-time
  useEffect(() => {
    setPasswordStrength(checkPasswordStrength(formData.password));
  }, [formData.password]);

  const handleInputChange = (e, field, section = null) => {
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    if (section) {
      setFormData({
        ...formData,
        [section]: {
          ...formData[section],
          [field]: value,
        }
      });
    } else {
      setFormData({
        ...formData,
        [field]: value,
      });
    }
  };

  // Email regex validation
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  // Password strength checker
  const checkPasswordStrength = (password) => {
    if (password.length < 6) return "Faible";
    if (/[a-z]/.test(password) && /[A-Z]/.test(password) && /[0-9]/.test(password)) return "Fort";
    return "Moyen";
  };

  // Function to handle API checking for email and pseudo
  const HandleCheckEmailAndPseudo = async () => {
    const newErrors = {};
    setIsChecking(true); // Set checking state to true to show the spinner
    
    try {
      // Trigger lazy query to check email and pseudo
      const result = await triggerCheckEmailPseudo({ email: formData.email, pseudo: formData.pseudo });
  
      if (result.error) {
        if (result.error.data?.error.includes('Email')) {
          newErrors.email = "L'e-mail est déjà utilisé";
        }
        if (result.error.data?.error.includes('Pseudo')) {
          newErrors.pseudo = "Le pseudo est déjà utilisé";
        }
      }
  
      // Only proceed to the next step if there are no errors
      if (Object.keys(newErrors).length === 0) {
        return true; // Indicating there are no errors
      }
    } catch (error) {
      console.error('Error checking email and pseudo:', error);
      newErrors.general = "Une erreur s'est produite lors de la vérification de l'email et du pseudo";
    } finally {
      setIsChecking(false); // Hide the spinner
    }
  
    setErrors(newErrors); // Set errors state
    return false; // Indicating there were errors
  };
  
  // Handle form submission of the first step
  const handleNext = async () => {
    const newErrors = {};
    if (!formData.pseudo) newErrors.pseudo = "Un pseudo est requis";
    if (!validateEmail(formData.email)) newErrors.email = "Format d'e-mail non valide";
    if (!formData.password) newErrors.password = "Le mot de passe est requis";
    if (formData.password !== formData.confirmPassword) newErrors.confirmPassword = "Les mots de passe ne correspondent pas";
    if (!formData.termsAccepted) newErrors.termsAccepted = "Vous devez accepter les conditions d'utilisation";
  
    if (Object.keys(newErrors).length === 0) {
      const canProceed = await HandleCheckEmailAndPseudo(); // Wait for the check to complete
  
      // Only move to the next step if the API check passes
      if (canProceed) {
        setStep((prev) => prev + 1);
      }
    } else {
      setErrors(newErrors);
    }
  };


  const handlePass = () => {
    if (step === 1) {
      setFormData((prevData) => ({
        ...prevData,
        personalInfo: {
          ...prevData.personalInfo,
          ignored: true,
        },
      }));
      setStep((prev) => prev + 1);
    } else if (step === 2) {
      setFormData((prevData) => ({
        ...prevData,
        vehicleInfo: {
          ...prevData.vehicleInfo,
          ignored: true,
        },
      }));
    }
  };


  const handleSubmit = async () => {
    setIsWaiting(true);
    setError('');
  
    try {
      const response = await addUser(formData).unwrap();
  
      if (response?.token) {
        setToken(response);
        setIsSignedIn(true); // Trigger `useEffect` for sign-in logic
      } else {
        setError('Registration successful, but no token received.');
      }
    } catch (err) {
      setError(err?.data?.error || 'Unknown error occurred.');
    } finally {
      setIsWaiting(false);
    }
  };

  const handleBack = () => setStep((prev) => prev - 1);

  // Framer motion variants for animations
  const slideIn = {
    hidden: { x: -300, opacity: 0 },
    visible: { x: 0, opacity: 1 },
    exit: { x: 300, opacity: 0 }
  };

  // Step 1: Account Info
  const renderAccountInfo = () => (
    <Box
      key="account-info"
      variants={slideIn}
      initial="hidden"
      animate="visible"
      exit="exit"
      transition={{ duration: 0.5 }}
    >
      <Typography 
        variant="h3"
        sx={{ 
          fontSize: 30,
          fontFamily: 'raspoutine',
          fontWeight: 'bold',
          color: '#0085FF',
          marginBottom: '10px'
        }}
      >
        Créez votre compte
      </Typography>
      
      <TextField
        label="Pseudo"
        fullWidth
        margin="normal"
        value={formData.pseudo}
        onChange={(e) => handleInputChange(e, 'pseudo')}
        error={!!errors.pseudo}
        helperText={errors.pseudo}
        sx={textFieldStyles}
      />
      <TextField
        label="Email"
        fullWidth
        margin="normal"
        value={formData.email}
        onChange={(e) => handleInputChange(e, 'email')}
        error={!!errors.email}
        helperText={errors.email}
        sx={textFieldStyles}
      />
      <TextField
        label="Mot de passe"
        fullWidth
        margin="normal"
        type="password"
        value={formData.password}
        onChange={(e) => handleInputChange(e, 'password')}
        error={!!errors.password}
        helperText={errors.password}
        sx={textFieldStyles}
      />
      <TextField
        label="Confirmer mot de passe"
        fullWidth
        margin="normal"
        type="password"
        value={formData.confirmPassword}
        onChange={(e) => handleInputChange(e, 'confirmPassword')}
        error={!!errors.confirmPassword}
        helperText={errors.confirmPassword}
        sx={textFieldStyles}
      />
      
      {passwordStrength && formData.password &&  (
        <Typography variant="body2" sx={{ color: passwordStrength === "Fort" ? "green" : passwordStrength === "Moyen" ? "orange" : "red" }}>
          Niveau de sécurité du mot de passe: {passwordStrength}
        </Typography>
      )}

      <FormControlLabel
        control={
          <Checkbox
            checked={formData.termsAccepted}
            onChange={(e) => handleInputChange(e, 'termsAccepted')}
            sx={{ color: '#0085FF' }}
          />
        }
        label="J'accepte les conditions d'utilisation"
        error={!!errors.termsAccepted}
      />
      <Button
  onClick={handleNext}
  fullWidth
  variant="contained"
  disabled={isChecking} // Disable the button when checking
  sx={{
    marginTop: '16px',
    backgroundColor: isChecking ? '#c0c0c0' : '#0085FF', // Change color when checking
    color: 'white',
    borderRadius: '77px',
    height: "3em",
    fontSize: "larger",
    "&:hover": {
      backgroundColor: isChecking ? '#c0c0c0' : '#006bbf', // Change hover color if not checking
    },
  }}
>
  {isChecking ? (
    <CircularProgress size={24} sx={{ color: 'white' }} /> // Show spinner when isChecking is true
  ) : (
    'Suivant' // Show button text when isChecking is false
  )}
</Button>
<Button
  onClick={() => navigate(-1)} // Navigate back to the previous page
  fullWidth
  variant="text" // Text variant for button without background
  sx={{
    marginTop: '16px',
    color: '#0085FF', // Custom text color
    fontWeight: 'bold', // Optional: make text bold
    fontSize: "larger",
    "&:hover": {
      color: '#006bbf', // Change text color on hover
      backgroundColor: 'transparent', // No background on hover
    },
  }}
>
  Retour
</Button>
    </Box>
  );

  // Step 2: Personal Info (with validation)
  const renderPersonalInfo = () => {
    const isNextDisabled = !formData.personalInfo.name || 
                           !formData.personalInfo.lastName || 
                           !formData.personalInfo.phone ||
                           !formData.personalInfo.dateOfBirth ||
                           !formData.personalInfo.wilaya || 
                           !formData.personalInfo.commune;
  
    return (
      <motion.div
        key="personal-info"
        variants={slideIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5 }}
      >
        <Typography 
          variant="h3"
          sx={{ 
            fontSize: 30,
            fontFamily: 'raspoutine',
            fontWeight: 'bold',
            color: '#0085FF',
            marginBottom: '10px'
          }}
        >
          Devenir bénévole
        </Typography>
  
        <TextField
          label="Nom"
          fullWidth
          margin="normal"
          value={formData.personalInfo.name}
          onChange={(e) => handleInputChange(e, 'name', 'personalInfo')}
          sx={textFieldStyles}
        />
        <TextField
          label="Prénom"
          fullWidth
          margin="normal"
          value={formData.personalInfo.lastName}
          onChange={(e) => handleInputChange(e, 'lastName', 'personalInfo')}
          sx={textFieldStyles}
        />
  
        <TextField
          label="Date de naissance"
          type="date"
          fullWidth
          margin="normal"
          value={formData.personalInfo.dateOfBirth}
          onChange={(e) => handleInputChange(e, 'dateOfBirth', 'personalInfo')}
          InputLabelProps={{ shrink: true }}
          sx={textFieldStyles}
        />
  
        {/* Wilaya Selection Dropdown */}
        <TextField
          select
          label="Wilaya de résidence"
          fullWidth
          margin="normal"
          value={formData.personalInfo.wilaya}
          onChange={(e) => handleInputChange(e, 'wilaya', 'personalInfo')}
          SelectProps={{ native: true }}
          sx={textFieldStyles}
        >
          <option value=""></option>
          {wilayas.map((wilaya, index) => (
            <option key={index} value={wilaya}>{wilaya}</option>
          ))}
        </TextField>
  
        <TextField
          label="Commune de résidence"
          fullWidth
          margin="normal"
          value={formData.personalInfo.commune}
          onChange={(e) => handleInputChange(e, 'commune', 'personalInfo')}
          sx={textFieldStyles}
        />
  


              <PhoneInput
                country={'dz'} // Default country set to Algeria
                onlyCountries={['dz', 'fr', 'us', 'gb', 'de', 'it', 'es']} // Allowed countries
                value={formData.personalInfo.phone}
                onChange={(phone) => handleInputChange({ target: { value: phone } }, 'phone', 'personalInfo')}
                inputStyle={{
                  width: '100%',
                  height: '56px',
                  borderRadius: '5px',
                  border: '1px solid #0085FF',
                  paddingLeft: '58px', // Space for flag
                  fontSize: '16px',
                }}
                buttonStyle={{
                  borderRadius: '5px',
                  border: 'none',
                  background: 'transparent',
                  margin: "10px"
                }}
              />

  
        {/* Transportation Means */}
        <FormControl component="fieldset" fullWidth margin="normal">
          <FormLabel component="legend" sx={{ color: '#006bbf' }}>
            Moyens de locomotion
          </FormLabel>
          <RadioGroup
            row
            value={formData.personalInfo.transportation}
            onChange={(e) => handleInputChange(e, 'transportation', 'personalInfo')}
            sx={{ gap: isMobile ? '.5em' : '1.5em' }}
          >
            {["Sans", "Moto", "Voiture", "Fourgonnette", "Fourgon", "Camion"].map((mode) => (
              <FormControlLabel
                key={mode}
                value={mode}
                control={<Radio sx={{ color: '#006bbf' }} />}
                label={mode}
                sx={{ color: '#006bbf' }}
              />
            ))}
          </RadioGroup>
        </FormControl>
  
        <TextField
          label="Profession"
          fullWidth
          margin="normal"
          value={formData.personalInfo.profession}
          onChange={(e) => handleInputChange(e, 'profession', 'personalInfo')}
          sx={textFieldStyles}
        />
  
        <TextField
          label="Autres compétences"
          fullWidth
          margin="normal"
          value={formData.personalInfo.otherSkills}
          onChange={(e) => handleInputChange(e, 'otherSkills', 'personalInfo')}
          sx={textFieldStyles}
        />
  
        <Button
          onClick={handleNext}
          fullWidth
          variant="contained"
          sx={{
            marginTop: '16px',
            backgroundColor: '#0085FF',
            color: 'white',
            borderRadius: '77px',
            "&:hover": { backgroundColor: '#006bbf' },
          }}
          disabled={isNextDisabled}
        >
          Suivant
        </Button>
        
        <Button
          onClick={handleBack}
          fullWidth
          variant="outlined"
          sx={{
            marginTop: '8px',
            color: '#0085FF',
            borderColor: '#0085FF',
          }}
        >
          Retour
        </Button>
  
        <Button
          onClick={handlePass}
          fullWidth
          variant="text"
          sx={{ marginTop: '8px', color: '#0085FF' }}
        >
          Passer
        </Button>
      </motion.div>
    );
  };

  // Step 3: Vehicle Info (with validation)
  const renderVehicleInfo = () => {
    const isNextDisabled = !formData.vehicleInfo.marque ||
    !formData.vehicleInfo.model ||
    !formData.vehicleInfo.year ||
    !formData.vehicleInfo.fuelType ||
    !formData.vehicleInfo.insuranceValidity ||
    !formData.vehicleInfo.technicalControlValidity;

    return (
      <motion.div
        key="vehicle-info"
        variants={slideIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.5 }}
      >
        <Typography 
          variant="h3"
          sx={{ 
            fontSize: 30,
            fontFamily: 'raspoutine',
            fontWeight: 'bold',
            color: '#0085FF',
            marginBottom: '10px',
            whiteSpace: 'nowrap',
            wordSpacing: '8px'
          }}
        >
          Souscrire à la rubrique 
        </Typography>
        <Typography 
          variant="h3"
          sx={{ 
            fontSize: 30,
            fontFamily: 'raspoutine',
            fontWeight: 'bold',
            color: '#0085FF',
            marginBottom: '10px',
          }}
        >
          Mon Véhicule
        </Typography>
        
        <TextField
        label="Marque du véhicule"
        fullWidth
        margin="normal"
        value={formData.vehicleInfo.marque}
        onChange={(e) => handleInputChange(e, 'marque', 'vehicleInfo')}
        sx={textFieldStyles}
      />
      <TextField
        label="Model du véhicule"
        fullWidth
        margin="normal"
        value={formData.vehicleInfo.model}
        onChange={(e) => handleInputChange(e, 'model', 'vehicleInfo')}
        sx={textFieldStyles}
      />
      
      {/* Vehicle Year Field */}
      <TextField
        label="Année du véhicule"
        fullWidth
        margin="normal"
        value={formData.vehicleInfo.year}
        onChange={(e) => handleInputChange(e, 'year', 'vehicleInfo')}
        inputProps={{ maxLength: 4, pattern: "\\d{4}" }} // Restrict to 4 digits
        placeholder="YYYY"
        sx={textFieldStyles}
      />

      {/* Fuel Type Radio Buttons */}
      <FormControl component="fieldset" fullWidth margin="normal">
      <FormLabel component="legend" sx={{ color: '#006bbf', '&.Mui-focused': { color: '#006bbf' } }}>
    Type de carburant
  </FormLabel>
  <RadioGroup
    row
    value={formData.vehicleInfo.fuelType}
    onChange={(e) => handleInputChange(e, 'fuelType', 'vehicleInfo')}
    sx={{
      gap: isMobile ? '.5em' : '1.5em', // Adjust gap based on mobile or not
    }}
  >
    <FormControlLabel 
      value="DIESEL" 
      control={<Radio sx={{ color: '#006bbf', '&.Mui-checked': { color: '#006bbf' } }} />} 
      label="DIESEL" 
      sx={{ color: '#006bbf' }} // Change label text color
    />
    <FormControlLabel 
      value="ESSENCE" 
      control={<Radio sx={{ color: '#006bbf', '&.Mui-checked': { color: '#006bbf' } }} />} 
      label="ESSENCE" 
      sx={{ color: '#006bbf' }} 
    />
    <FormControlLabel 
      value="ESS.GPL" 
      control={<Radio sx={{ color: '#006bbf', '&.Mui-checked': { color: '#006bbf' } }} />} 
      label="ESS.GPL" 
      sx={{ color: '#006bbf' }} 
    />
  </RadioGroup>
</FormControl>

      {/* Insurance Validity Date */}
      <TextField
        label="Validité de l'assurance"
        type="date"
        fullWidth
        margin="normal"
        value={formData.vehicleInfo.insuranceValidity}
        InputLabelProps={{
          shrink: true, // This forces the label to always appear above the input field
        }}
        onChange={(e) => handleInputChange(e, 'insuranceValidity', 'vehicleInfo')}
        InputProps={{ inputProps: { min: today } }} // Ensure date is in the future
        sx={textFieldStyles}
      />

      {/* Vehicle Technical Control Validity Date */}
      <TextField
        label="Validité du contrôle technique"
        type="date"
        fullWidth
        margin="normal"
        value={formData.vehicleInfo.technicalControlValidity}
        InputLabelProps={{
          shrink: true, // This forces the label to always appear above the input field
        }}
        onChange={(e) => handleInputChange(e, 'technicalControlValidity', 'vehicleInfo')}
        InputProps={{ inputProps: { min: today } }} // Ensure date is in the future
        sx={textFieldStyles}
      />
        <Button
          onClick={handleSubmit}
          fullWidth
          variant="contained"
          sx={{
            marginTop: '16px',
            backgroundColor: '#0085FF',
            color: 'white',
            borderRadius: '77px',
            "&:hover": {
              backgroundColor: '#006bbf',
            },
          }}
          disabled={isNextDisabled} // Disable Next button if required fields are missing
        >
          {waiting ? "Veuillez patienter" : "Finaliser"}
        </Button>
        <Button
          onClick={handleBack}
          fullWidth
          variant="outlined"
          sx={{
            marginTop: '8px',
            color: '#0085FF',
            borderColor: '#0085FF',
          }}
        >
          Retour
        </Button>
        <Button
          onClick={handlePass}
          fullWidth
          variant="text"
          sx={{ marginTop: '8px', color: '#0085FF' }}
        >
          Passer
        </Button>
      </motion.div>
    );
  };

  return (
    <Box className="CreateAccount" sx={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
  {/* Left side (Scrollable Form Container) */}
  <Box
    sx={{
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      padding: '2em',
      height: '100vh', // Make sure it fills the viewport
      overflow: 'hidden', // Prevent whole page from scrolling
    }}
  >
    <Box
      sx={{
        width: '100%',
        height: '100%', // Full height so it scrolls
        maxHeight: '90vh', // Ensures it doesn't get cut off
        overflowY: 'auto', // Enables scrolling inside
        padding : "0 1em",
        backgroundColor: 'white',
      }}
    >
       {step === 0 && renderAccountInfo()}
      {step === 1 && renderPersonalInfo()}
      {step === 2 && renderVehicleInfo()}
    </Box>
  </Box>

  {/* Right side (Background Image) */}
  {!isMobile && (
    <Box
      sx={{
        flex: 1,
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <img
        src={BgSVG}
        alt="Background"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
    </Box>
  )}
</Box>

  )
};

export default CreateAccount;
