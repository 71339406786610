import React, { useState, useEffect } from "react";
import { useGetFacebookFeedQuery } from "state/api";
import "./TraficWidget.css";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import accidentImg from "assets/accident.png";
import trafficImg from "assets/traffic.png";
import travauxImg from "assets/travaux.png";
import Jam from 'assets/traffic-jam.png';
import emptyImg from "assets/danger-sign.png";
import searchImg from "assets/Search.png";
import RegionsDropDown from "./RegionsDropDown";
import WeatherApp from "./Weather";
import TrafficCounts from "./TraficCount";

const countTrafficTypes = (trafficData) => {
  const typeCounts = {
    iAccident: 0,
    iEmbouteillage: 0,
    iTravaux: 0,
    Accident: 0,
    Embouteillage: 0,
    Travaux: 0,
  };

  trafficData.forEach((item) => {
    const types = item.type || []; // Handle cases where type might be null
    types.forEach((type) => {
      if (type in typeCounts) {
        typeCounts[type]++;
      }
    });
  });

  // Combine counts from types with and without 'i' prefix
  typeCounts.iAccident += typeCounts.Accident;
  typeCounts.iEmbouteillage += typeCounts.Embouteillage;
  typeCounts.iTravaux += typeCounts.Travaux;

  return {
    iAccident: typeCounts.iAccident,
    iEmbouteillage: typeCounts.iEmbouteillage,
    iTravaux: typeCounts.iTravaux,
  };
};

const TraficWidget = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // Fetch the original TraficData
  const { data: TraficData, isLoading: TraficDataIsLoading, error: TraficDataError } = useGetFacebookFeedQuery();

  // State to hold the region-filtered data
  const [regionFilteredData, setRegionFilteredData] = useState([]);
  
  // State to hold the fully filtered data (region + search + type)
  const [filteredData, setFilteredData] = useState([]);

  const [selectedTypes, setSelectedTypes] = useState(["All"]); // Default to "All" selected
  const [searchTerm, setSearchTerm] = useState(""); // State to track search term

  const counts = countTrafficTypes(TraficData?.posts ? TraficData?.posts : []);

  // Extract types from TraficData
  const extractedTypes = TraficData?.posts?.map((post) => post.type).flat();
  const types = [...new Set(extractedTypes?.filter((type) => type && type.length > 0))];

  // Handle updating the regionFilteredData when newList is passed from RegionsDropDown
  const handleFilterChange = (newList) => {
    if (!newList || newList.length === 0) {
      setRegionFilteredData(TraficData?.posts || []); // Reset to full list if no regions are selected
    } else {
      setRegionFilteredData(newList); // Set region-filtered data
    }
  };

  // Handle type click for single or multi-select
  const handleTypeClick = (type) => {
    if (type === "All") {
      setSelectedTypes(["All"]);
    } else {
      setSelectedTypes((prevState) => {
        if (prevState.includes(type)) {
          const newSelection = prevState.filter((t) => t !== type);
          return newSelection.length > 0 ? newSelection : ["All"];
        } else {
          return prevState.includes("All") ? [type] : [...prevState, type];
        }
      });
    }
  };

  // Handle search filtering
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  // useEffect to update `filteredData` based on regionFilteredData, search, and type filters
  useEffect(() => {
    if (regionFilteredData?.length > 0) {
      // Apply search and type filtering on top of region-filtered data
      const filteredPosts = regionFilteredData.filter((post) => {
        const matchesSearchTerm =
          post.message?.toLowerCase().includes(searchTerm) ||
          post.region?.toLowerCase().includes(searchTerm) ||
          post.type?.some((type) => type.toLowerCase().includes(searchTerm)) ||
          post.uri?.toLowerCase().includes(searchTerm);

        return (
          (selectedTypes.includes("All") || post.type?.some((type) => selectedTypes.includes(type))) &&
          matchesSearchTerm
        );
      });

      setFilteredData(filteredPosts); // Update the fully filtered data
    }
  }, [regionFilteredData, searchTerm, selectedTypes]); // This effect runs when region, search, or type changes

  // Helper function to format the date to "26/09/2024 à 17:32"
  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}/${month}/${year} - ${hours}:${minutes}`;
  };

  // Helper function to get the image based on post.type
  const getImageForType = (type) => {
    if (!type) return emptyImg; // Return emptyImg if type is null or undefined

    if (type.includes("iAccident") || type.includes("حادث_مرور")) return accidentImg;
    if (type.includes("iTrafic_routier") || type.includes("Embouteillage")) return trafficImg;
    if (type.includes("iTravaux") || type.includes("أشغال")) return travauxImg;
    if (type.includes("Embouteillage") || type.includes("iEmbouteillage")) return Jam;

    return emptyImg; // Return emptyImg if no other conditions match
  };

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection : isMobile ? "column-reverse" : "row-reverse",
        flexWrap: "nowrap",
        flexGrow: "1",
        width: "100%",
        padding: "2em",
        minHeight: "40vh",
        background: "#FFFDFD",
        borderRadius: "25px",
        marginTop: "5rem",
        zIndex: 1,
        overflow: "visible",
      }}
    >
      <div
        className="search"
        style={{
          position: "absolute",
          minWidth: "20rem",
          maxWidth: isMobile ? "5rem" : "20rem",
          background: "rgba(217,217,217,75%)",
          minHeight: "6rem",
          top: "0",
          left: "0",
          borderRadius: "30px 30px 0px 0px",
          transform: "translateY(-50%)",
          zIndex: -1,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: 0,
            right: 0,
            height: "50%",
            backgroundColor: "#FFFDFD",
            zIndex: 1,
            borderRadius: "25px 0px 0px",
          }}
        />
        <div
          style={{
            display: "flex",
            width : isMobile ? "13rem" : "100%",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
            flexGrow: "1",
            marginTop: ".5em",
            flexWrap: "nowrap",
          }}
        >
          <img width={24} src={searchImg} alt="Search" />
          <input
            onChange={handleSearchChange}
            className="ita-search"
            style={{
              width : isMobile ? "50%" : "70%",
              padding: "6px 12px",
              border: "1px solid #ccc",
              borderRadius: "15px",
              outline: "none",
              fontSize: "16px",
              flexGrow: ".4",
              backgroundColor: "#f9f9f9",
              boxShadow: "inset 0 2px 4px rgba(0, 0, 0, 0.1)",
              color: "#000",
            }}
            type="text"
            placeholder="Search..."
          />
        </div>

        {isMobile ? 
         <div
         style={{
           display: "flex",
           justifyContent: "center",
           position: "absolute",
           top: '0px',
           left: "8rem",
           width: "14rem",
           minHeight: "3rem",
           maxHeight: "3rem",
           borderRadius: "30px 30px 0px 0px",
           zIndex: -1,
         }}
       >
         <RegionsDropDown posts={TraficData?.posts} onFilterChange={handleFilterChange} isMobile={isMobile} />
       </div>

         : 

            <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "absolute",
              top: '0px',
              left: "22rem",
              width: "15rem",
              background: "rgba(217,217,217,75%)",
              minHeight: "3rem",
              maxHeight: "3rem",
              borderRadius: "30px 30px 0px 0px",
              zIndex: -1,
            }}
          >
            <RegionsDropDown posts={TraficData?.posts} onFilterChange={handleFilterChange} isMobile={isMobile} />
          </div>
  

        }

        
       
      </div>

      <div style={{ minWidth: "75%" }}>
        <div
          style={{
            display: "flex",  
            justifyContent :"center",
            overflowX: "auto",
            scrollBehavior: "smooth",
            gap: "1em",
            paddingBottom: "1em",
            scrollbarWidth: "none",
          }}
        >
          <ul
            style={{
              listStyle: "none",
              padding: 0,
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              gap: "1em",
              userSelect: "none",
              scrollSnapType: "x mandatory",
              WebkitOverflowScrolling: "touch",
              marginLeft: isMobile ? "28em" : "0em",
            }}
          >
            <li
              className={`trafficFilterBtn ${selectedTypes.includes("All") ? "active" : ""}`}
              onClick={() => handleTypeClick("All")}
              style={{
                scrollSnapAlign: "start",
              }}
            >
              Tout afficher
            </li>

            {types.map((type) => (
              <li
                className={`trafficFilterBtn ${selectedTypes.includes(type) ? "active" : ""}`}
                key={type}
                onClick={() => handleTypeClick(type)}
                style={{
                  scrollSnapAlign: "start",
                }}
              >
                {type}
              </li>
            ))}
          </ul>
        </div>

        <div
          style={{
            maxHeight: isMobile ? "50vh" : "35vh",
            minHeight: isMobile ? "50vh" : "35vh",
            overflowY: "auto",
            padding: isMobile ? "0.5em" : "1em",
            marginTop: "1em",
            borderRadius: "10px",
            background: "#fff",
          }}
        >
          {filteredData?.map((post, index) => (
            <div
              key={index}
              style={{
                padding: isMobile ? "0.5em" : "1em",
                backgroundColor: "rgba(217,217,217,25%)",
                marginBottom: "1em",
                borderRadius: "10px",
                display: "flex",
                flexDirection: isMobile ? "column" : "row-reverse",
                alignItems: isMobile ? "center" : "flex-start",
                textAlign: isMobile ? "center" : "right",
              }}
            >
              {getImageForType(post.type) && (
                <img
                  src={getImageForType(post.type)}
                  alt={post.type}
                  style={{
                    width: isMobile ? "40px" : "50px",
                    height: isMobile ? "40px" : "50px",
                    marginRight: isMobile ? "0" : "0",
                    marginLeft: isMobile ? "0" : "2rem",
                    marginBottom: isMobile ? "1rem" : "0",
                  }}
                />
              )}
              <div style={{ flex: 1 }}>
                <div style={{ textAlign: isMobile ? "center" : "right", fontSize: isMobile ? "16px" : "18px" }}>
                  {post.message}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row-reverse",
                    justifyContent: isMobile ? "center" : "space-between",
                    textAlign: "left",
                    fontSize: "0.8em",
                    color: "#888",
                    marginTop: "10px",
                  }}
                >
                  <div>{formatDate(post.created_time)} - {post.region}</div>
                  <div>
                    <a
                      style={{
                        color: "#0085FF",
                        cursor: "pointer",
                        textAlign: isMobile ? "center" : "left",
                        fontSize : "1.3em"
                      }}
                      href={`https://www.facebook.com/${post.uri}/posts/${post.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      أكثر تفاصيل
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

        <div style={{ width: "100%" , display:"flex" , flexDirection : "column" , justifyContent : "center" , flexWrap : "nowrap" , gap : "1.5em" , alignContent : "center" , alignItems : "center"  }}>
          <div style={{ width: '100%', height: "70%" }}>
            <TrafficCounts counts={counts} isMobile={isMobile} />
          </div>
          {!isMobile && (
          <div style={{ width: '100%', height: "20%" }}>
            <WeatherApp />
          </div>
          )}
        </div>
      
    </div>
  );
};

export default TraficWidget;
